import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const ConstructionBidding: React.FC = () => {
  return (
    <>
      <SEO
        title="Principles for submitting better bids"
        description="In this article you will learn about construction bidding and Points to consider when bidding"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          The main principles of construction bidding
        </Typography>
        <Typography variant="body1">
          To create a winning proposal for a construction project, knowledge,
          skills, and experience are essential. Contractors need to set
          competitive prices. After all, an incorrect or underestimated rate can
          lead to a real lack of profit for the project. Therefore, it is worth
          considering ways to improve the results of rates and, for a start,
          familiarize yourself directly with the concept of construction
          tenders.
        </Typography>
        <Typography variant="h2" color="blue">
          What is construction bidding?
        </Typography>
        <Typography variant="body1">
          So, how does bidding work in construction? A construction tender is a
          tender followed by the conclusion of a contract for the performance of
          certain works related to construction. Participation in tenders is one
          of the best ways for a customer to receive quality services at a
          favorable price, and it allows a contractor to receive a large order
          and good prospects for the future.
        </Typography>
        <Typography variant="body1">
          Having dealt with what is bidding in construction, let's move on to a
          more detailed consideration of what is the bidding process in
          construction.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/construction-bidding/image-1.jpg"
            alt="Construction bidding"
            title="Construction bidding"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          The main principles of tendering
        </Typography>
        <Typography variant="body1">
          Knowing what tendering means, it is important to understand that
          regardless of the size of the project, it is important to follow the
          principles of managing the tendering process.
        </Typography>
        <Typography variant="h3">It takes time to trade</Typography>
        <Typography variant="body1">
          If you already understand what a tendering process is, then you should
          appreciate the fact that it can take several hours to apply, and
          suppliers need at least a week to prepare applications.
        </Typography>
        <Typography variant="h3">
          Detailed plans are needed for a robust bidding process
        </Typography>
        <Typography variant="body1">
          Having studied the question of what tendering procedure is, it is
          important to understand that the preparation of tender documents is
          very important. After all, for the entire time of work, the team will
          work with the documentation of architects, so all drawings must be
          clear and correct.
        </Typography>
        <Typography variant="h3">
          Choosing a bid with a low price does not always result in quality
        </Typography>
        <Typography variant="body1">
          In concluding the consideration of the principles, it is important to
          remember that when choosing partners, one should not rely only on low
          prices. After all, all participants in the process must understand
          what is tendering in construction and how important it is to choose a
          reliable partner. The reason for the low price should be investigated
          and the effectiveness of such a project evaluated according to the
          initial planning.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/construction-bidding/image-2.jpg"
            alt="The main principles of tendering"
            title="The main principles of tendering"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          10 points to consider when bidding process in construction
        </Typography>
        <Typography variant="body1">
          The bidding process in construction includes many important nuances
          that, if executed correctly, lead to an excellent result. Knowing the
          types of bidding in construction and the basic principles of bidding,
          you must also follow the step-by-step discussed below to get a project
          that will be comfortable and risk-free for you.
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>
            Choose good projects. You don't have to take on every project that
            comes your way. Even if you spent time on the evaluation, but still
            realized that the project is not suitable for your company, move on
            to another evaluation so as not to waste even more time or even the
            company's reputation as a result.
          </li>
          <li>
            View the property and participate in the preliminary meeting. This
            is especially important if a renovation is planned. So you can
            assess the risks and understand the availability of the project for
            your implementation. View drawings and all available information
            about the object. Ignoring this point can damage your profitability.
          </li>
          <li>
            Refine information if necessary. If you have questions about the
            project, please clarify them before applying. This will help you
            submit a competitive and accurate offer.
          </li>
          <li>
            Make accurate calculations. The estimator needs to quantify the work
            on the drawings. Review the plan and specifications. Check available
            parts and materials. After all, a detailed check is the best way.
          </li>
          <li>
            Use modern software to avoid math mistakes. In the modern world,
            there are many opportunities to automate the calculation process.
            Estimating software will give you more accurate results than Excel,
            for example. But even despite the use of modern software, it is
            still important to check everything personally again after the
            calculations. Especially if the calculations are carried out already
            on the day of trading, then the rush and a simple human factor is
            the reason for getting errors that can affect your reputation or
            give out losses in the long run.
          </li>
          <li>
            Evaluate all data from suppliers and subcontractors. It is important
            to be sure that subcontractors will be able to complete the project.
            It is best if you familiarize yourself with information about
            subcontractors - their qualifications and past projects. You need to
            study proposals from at least 3 companies for each part of the
            project. So you can analyze each performer as much as possible to
            select reliable employees.
          </li>
          <li>
            Assess the risks. There are no completely risk-free projects.
            Therefore, it is very important to analyze and manage them.
            Understand how best to minimize risks. Evaluate even minimal and
            seemingly unrealistic risks to understand whether you can cope with
            them at any stage of the project.
          </li>
          <li>
            Salary. If you get your labor costs right, you'll be doing a huge
            amount of work on the application, because it's one of the hardest
            steps. It is important to estimate the number of materials to
            understand the labor costs for their use. A complete list of
            materials gives the appraiser the total number of hours worked.
            Rates should not be lower than the average for the region.
          </li>
          <li>
            The cost of materials and equipment. Estimate your quoted cost
            against the cost of purchasing materials and current market prices.
            Buying in bulk will help you keep the price down compared to the
            unit price. You must have bids from at least 3 vendors for price
            comparison. Don't forget to also keep track of the equipment you
            need to buy or rent. If your company already owns the equipment,
            consider depreciation. Don't forget about fuel and transport costs.
          </li>
          <li>
            Full completion of documents. Carefully read the "Invitation to Bid"
            and "Instructions for Bidders" - and follow all the rules. Consider
            the deadlines and nuances of filling out applications.
          </li>
        </ol>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/construction-bidding/image-3.jpg"
            alt="Bidding process in construction"
            title="Bidding process in construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          Applying these 10 principles will increase the chances of success and
          bring profit to your company.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default ConstructionBidding
